<template>
  <ion-menu content-id="main-content" type="push" color="primary">
    <ion-content color="primary" v-if="yo?._id?.length">
      <p>&nbsp;</p>
      <ion-img src="img/icono_gris.svg" class="menulogo"></ion-img>
      <ion-item lines="none" detail="false" color="transparent">
        <ion-label @click="go('/')">
          <h2>{{ yo.nombre || "Invitado" }}</h2>
          <p>{{ yo.email || "Inicia sesión" }} {{ yo.rol || "" }}</p>
        </ion-label>
        <ion-button
          slot="end"
          fill="clear"
          color="medium"
          size="small"
          @click="go('/config')"
        >
          <IonIcon :icon="cogOutline" />
        </ion-button>
        <ion-button
          slot="end"
          fill="clear"
          @click="salir"
          size="small"
          color="light"
        >
          <ion-icon :icon="logOutOutline"></ion-icon>
        </ion-button>
      </ion-item>

      <ion-menu-toggle auto-hide="false" v-if="!yo._id">
        <ion-item router-link="/login" color="transparent">
          <ion-icon slot="start" :icon="logIn"></ion-icon>
          <ion-label>Iniciar sesión</ion-label>
        </ion-item>
      </ion-menu-toggle>
      <div
        v-for="(manifiesto, nombreModulo) in menuArbol"
        :key="nombreModulo"
        class="ion-padding-vertical"
      >
        <ion-item
          lines="none"
          @click="toggleMostrar(nombreModulo)"
          color="transparent"
        >
          <ion-icon
            slot="end"
            v-if="iconos[manifiesto.icono]"
            :icon="iconos[manifiesto.icono]"
            :color="mostrar[nombreModulo] ? 'light' : 'medium'"
          />
          <ion-label
            text-wrap
            :color="mostrar[nombreModulo] ? 'light' : 'medium'"
          >
            <h1>{{ manifiesto.nombre }}</h1>
            <p>{{ manifiesto.descripcion }}</p>
          </ion-label>
        </ion-item>
        <Transition>
          <div v-if="mostrar[nombreModulo]">
            <ion-menu-toggle
              auto-hide="false"
              v-for="l in manifiesto.menuArbol.filter((u) => !u.ocultar)"
              :key="l.label"
            >
              <ion-item
                :router-link="l.ruta"
                :button="true"
                detail="false"
                :lines="l.label != '' ? 'solid' : 'none'"
                color="transparent"
              >
                <IonIcon
                  :icon="iconos[l.icono]"
                  color="light"
                  slot="end"
                  size="small"
                  v-if="l.label != '' && iconos[l.icono]"
                ></IonIcon>
                <ion-label>{{ l.label }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <p>&nbsp;</p>
          </div>
        </Transition>
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar v-if="msg == ''" color="secondary">
        <ion-title class="ittoni-logo">
          <ion-img src="img/completo.svg" />
        </ion-title>
      </ion-toolbar>
      <ion-item lines="none" v-else>
        <ion-label text-wrap>{{ msg }}</ion-label>
      </ion-item>
    </ion-footer>
  </ion-menu>
</template>
<style>
.menulogo {
  padding-left: 35%;
  padding-right: 35%;
  padding-bottom: 5%;
}

.subicono {
  position: relative;
  display: block;
  float: left;
  max-width: 36px;
  max-height: 36px;
  top: 55px;
  left: 40vw;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
<script>
import Api from "@/common/Api/Api";
const yo = Api.fetchLocal("yo") || {};
const empresa = Api.fetchLocal("empresadb") || {};
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenu,
  IonMenuToggle,
  IonButton,
  IonFooter,
  IonToolbar,
  IonTitle,
  IonImg,
  menuController,
} from "@ionic/vue";
import { cogOutline, logIn, logOutOutline } from "ionicons/icons";
import {
  defineComponent,
  //ref
} from "vue";
export default defineComponent({
  name: "IttoniMenu",
  components: {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonMenu,
    IonMenuToggle,
    IonButton,
    IonFooter,
    IonToolbar,
    IonTitle,
    IonImg,
  },
  props: {
    estoyEnDashboard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      yo,
      empresa,
      cogOutline,
      logIn,
      logOutOutline,
      modoProduccion: Api.modoProduccion,
      menuArbol: {}, //Api.fetchLocal('menuArbol') || {},
      msg: "",
      mostrar: {},
      iconos: {},
    };
  },
  mounted() {
    let vm = this;
    let u = import("ionicons/icons");
    u.then((v) => {
      vm.iconos = v;
    });
    let nombreModulo = Api.fetchLocal("menuMostrarModulo") || "";
    let menuArbolOriginal = Api.fetchLocal("menuArbol") || {};
    for (let nombreModulo in menuArbolOriginal) {
      if ((empresa.productos || {})[nombreModulo] || this.yo.sesionOffline)
        this.menuArbol[nombreModulo] = menuArbolOriginal[nombreModulo];
    }
    this.toggleMostrar(nombreModulo);
    setInterval(this.comprobarYo, 1500);
  },
  methods: {
    go(str) {
      this.$router.push(str);
      menuController.close();
    },
    toggleMostrar(nombreModulo) {
      Api.saveLocal("menuMostrarModulo", nombreModulo);
      for (let i in this.mostrar) this.mostrar[i] = false;
      this.mostrar[nombreModulo] = true;
      //if (this.menuArbol[nombreModulo].menuArbol.length == 1) this.go(this.menuArbol[nombreModulo].menuArbol[0].ruta)
      this.$forceUpdate();
    },
    urlize(str) {
      return str.startsWith("data:") || str.startsWith("http")
        ? str
        : Api.cdn + "/" + str;
    },
    comprobarYo() {
      this.yo = Api.fetchLocal("yo") ?? {};
    },
    async salir() {
      Api.logout();
    },
  },
});
</script>
