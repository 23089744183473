const routes = [{
    path: '/config',
    component: () => import('@/common/Config/Config')
  },
  {
    path: '/config/usuarios',
    component: () => import('@/common/Config/Usuarios')
  },
  {
    path: '/config/usuarios/:id',
    component: () => import('@/common/Config/Usuario'),
    props: true
  },
  {
    path: '/configmodulo/usuarios/:setmodulo',
    component: () => import('@/common/Config/Usuarios'),
    props: true
  },
  {
    path: '/configmodulo/usuarios/:setmodulo/:id',
    component: () => import('@/common/Config/Usuario'),
    props: true
  },
  {
    path: '/configmodulo/clientes/:setmodulo',
    component: () => import('@/common/Config/Clientes'),
    props: true
  },
  {
    path: '/configmodulo/clientes/:setmodulo/:id',
    component: () => import('@/common/Config/Cliente'),
    props: true
  },
  {
    path: '/config/empresa',
    component: () => import('@/common/Config/Empresa')
  },
  {
    path: '/config/modulos',
    component: () => import('@/common/Config/ModulosAdquiridos')
  }
]
export default routes