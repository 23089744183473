let rutaLocal = "@/apps/Tarea/";
rutaLocal = "./";
import Api from "@/common/Api/Api";
const routes = [
  {
    path: "/captura",
    name: "CapturaMenu",
    component: () =>
      import(/* webpackChunkName: "captura" */ rutaLocal + "Captura"),
  },
  {
    path: "/captura/manual",
    name: "CapturaManual",
    component: () =>
      import(/* webpackChunkName: "capturamanual" */ rutaLocal + "Manual"),
  },
  {
    path: "/captura/manual/:id",
    name: "CapturaManualId",
    component: () =>
      import(/* webpackChunkName: "capturamanualid" */ rutaLocal + "Manual"),
    props: true,
  },
  {
    path: "/captura/manualcliente/:idCliente",
    name: "CapturaManualIdCliente",
    component: () =>
      import(
        /* webpackChunkName: "capturamanualidcliente" */ rutaLocal + "Manual"
      ),
    props: true,
  },
  {
    path: "/captura/semi",
    name: "CapturaSemi",
    component: () =>
      import(/* webpackChunkName: "capturasemi" */ rutaLocal + "Semi"),
  },
  {
    path: "/captura/semi/:bot",
    name: "CapturaSemiBot",
    component: () =>
      import(/* webpackChunkName: "capturasemibot" */ rutaLocal + "Semi"),
    props: true,
  },
  {
    path: "/captura/semi/:tipo",
    name: "CapturaSemiBotTipo",
    component: () =>
      import(/* webpackChunkName: "capturasemibottipo" */ rutaLocal + "Semi"),
    props: true,
  },
  {
    path: "/polizas",
    name: "CapturaPolizas",
    component: () =>
      import(/* webpackChunkName: "capturapolizas" */ rutaLocal + "Polizas"),
  },
  {
    path: "/importarpolizas",
    name: "ImportarPolizas",
    component: () =>
      import(
        /* webpackChunkName: "importarpolizas" */ rutaLocal + "ImportarPolizas"
      ),
  },
  {
    path: "/polizas/:id",
    name: "CapturaPolizaId",
    component: () =>
      import(/* webpackChunkName: "capturapolizaid" */ rutaLocal + "Poliza"),
    props: true,
  },
];
const manifiesto = {
  // El nombre del módulo, como aparece en menús y en permisos
  nombre: "Captura",
  // descripcion aparece en el menú
  descripcion: "Captura automática y semiautomática de pagos",
  tarifa: 0,
  // Ícono principal
  icono: "calculatorOutline",
  // Permisos. Configurables desde /config/usuarios/:idUsuario
  permisosPosibles: [],
  // Al hacer clic en el botón en HelloWorld, la app redirigirá aquí
  rutaPrincipal: "/captura",
  // menuArbol alberga los links que se generan en el menú
  menuArbol: [
    {
      ruta: "/captura/manual",
      label: "Captura manual",
      icono: "calculatorOutline",
    },
    {
      ruta: "/captura/semi",
      label: "Captura semiautomática",
      icono: "keypadOutline",
    },
    {
      ruta: "/polizas",
      label: "Pólizas",
      icono: "fileTrayFullOutline",
    },
    {
      ruta: "/importarpolizas",
      label: "Importar pólizas",
      icono: "cloudUploadOutline",
      ocultar: true,
    },
  ],
  // Variables globales para el módulo, configurables desde /config/empresa.
  // Si existe esta variable en el usuario, se sobrepondrá a la variable global de empresa.
  variables: [],
};
let menuArbol = Api.fetchLocal("menuArbol") || {};
menuArbol[manifiesto.nombre] = manifiesto;
Api.saveLocal("menuArbol", menuArbol);
export default routes;

/*

Instrucciones:

- Convertir Vue() a defineComponent()
- Cambiar div.ion-page a IonPage
- Importar todos los componentes
- Cambiar todos los íconos a IonIcon, importarlos desde 'ionicons/icons' y exportarlos en data()
- Importar IonMenuButton y cambiarlo a slot="start"
- Cambiar los modelos de Vue() a standalone {...datos,...metodos}
- Filtros depreciados!
  - import {format} from 'date-fns'
  - DD cambia a dd
  - yyyy cambia a yyyy
- Ya que Wanshitong guarda fechas actualas, guardar TODAS las fechas secundarias en el formato nativo de su input
- Todos los input, checkbox y cualquier cosa que use $event DEBE ser importado
- IonContent debe ser importado o no habrá scroll!
- Todos los eventos tendrán que pasarse a los tags de los componentes
- setup() NO puede ser asíncrono!

*/
