let rutaLocal = "@/apps/CRM/";
rutaLocal = "./";
import Api from "@/common/Api/Api";
const routes = [
  {
    path: "/crm",
    name: "CRMHome",
    component: () =>
      import(/* webpackChunkName: "crmhome" */ rutaLocal + "Lista"),
  },
  {
    path: "/crm/clientes",
    name: "CRMClientes",
    component: () =>
      import(/* webpackChunkName: "crmclientes" */ rutaLocal + "Clientes"),
  },
  {
    path: "/crm/clientes/:id",
    name: "CRMClienteID",
    component: () =>
      import(/* webpackChunkName: "crmclienteid" */ rutaLocal + "Cliente"),
    props: true,
  },
  {
    path: "/crm/pagos",
    name: "CRMPagosLista",
    component: () =>
      import(/* webpackChunkName: "crmpagos" */ rutaLocal + "Pagos2"),
  },
  {
    path: "/crm/pagos2",
    name: "CRMPagos2",
    component: () =>
      import(/* webpackChunkName: "crmpagos" */ rutaLocal + "Pagos"),
  },
  {
    path: "/crm/calendario",
    name: "CRMCalendario",
    component: () =>
      import(/* webpackChunkName: "crmcalendario" */ rutaLocal + "Calendario"),
  },
];
const manifiestoCal = {
  nombre: "Vista Calendario",
  descripcion: "Calendario de pagos y vigencias",
  tarifa: 0,
  icono: "calendarOutline",
  permisosPosibles: [
    "Puede crear tareas",
    "Puede eliminar tareas",
    "Puede ver tareas de otros usuarios",
  ],
  rutaPrincipal: "/crm/calendario",
  menuArbol: [
    {
      ruta: "/crm/calendario",
      label: "Vista Calendario",
      permiso: "Puede crea tareas",
      icono: "calendarOutline",
    },
  ],
};
const manifiesto = {
  nombre: "Seguimiento de pagos",
  descripcion: "Seguimiento de clientes y trazabilidad",
  tarifa: 0,
  icono: "statsChartOutline",
  permisosPosibles: [
    "Puede crear tareas",
    "Puede eliminar tareas",
    "Puede ver tareas de otros usuarios",
  ],
  rutaPrincipal: "/crm/pagos",
  menuArbol: [
    {
      ruta: "/crm/pagos",
      label: "Pagos (v. nueva)",
      permiso: "Puede crea tareas",
      icono: "statsChartOutline",
    },
    {
      ruta: "/crm/pagos2",
      label: "Pagos (v. anterior)",
      permiso: "Puede crea tareas",
      icono: "statsChartOutline",
    },
  ],
};
let menuArbol = Api.fetchLocal("menuArbol") || {};
menuArbol[manifiesto.nombre] = manifiesto;
menuArbol[manifiestoCal.nombre] = manifiestoCal;
Api.saveLocal("menuArbol", menuArbol);
export default routes;
