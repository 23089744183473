let rutaLocal = "@/apps/Catalogos/";
rutaLocal = "./";
import Api from "@/common/Api/Api";
const routes = [
  {
    path: "/catalogos",
    name: "CatalogosLista",
    component: () =>
      import(/* webpackChunkName: "catalogoslista" */ rutaLocal + "Lista"),
  },
  {
    path: "/catalogos/clientes",
    name: "CatalogosClientes",
    component: () =>
      import(
        /* webpackChunkName: "catalogosclientes" */ rutaLocal + "Clientes"
      ),
  },
  {
    path: "/catalogos/clientes/:id",
    name: "CatalogosClienteId",
    component: () =>
      import(
        /* webpackChunkName: "catalogosclienteid" */ rutaLocal + "Cliente"
      ),
    props: true,
  },
  {
    path: "/catalogos/aseguradoras",
    name: "CatalogosAseguradoras",
    component: () =>
      import(
        /* webpackChunkName: "catalogosaseguradoras" */ rutaLocal +
          "Aseguradoras"
      ),
  },
  {
    path: "/catalogos/aseguradoras/:id",
    name: "CatalogosAseguradoraId",
    component: () =>
      import(
        /* webpackChunkName: "catalogosaseguradoraid" */ rutaLocal +
          "Aseguradora"
      ),
    props: true,
  },
  {
    path: "/catalogos/productos",
    name: "CatalogosProductos",
    component: () =>
      import(
        /* webpackChunkName: "catalogosproductos" */ rutaLocal + "Productos"
      ),
  },
  {
    path: "/catalogos/productos/:id",
    name: "CatalogosProductoId",
    component: () =>
      import(
        /* webpackChunkName: "catalogosproductoid" */ rutaLocal + "Producto"
      ),
    props: true,
  },
];
const manifiesto = {
  // El nombre del módulo, como aparece en menús y en permisos
  nombre: "Catálogos",
  // descripcion aparece en el menú
  descripcion: "Catálogos de control",
  tarifa: 0,
  // Ícono principal
  icono: "appsOutline",
  // Permisos. Configurables desde /config/usuarios/:idUsuario
  permisosPosibles: [],
  // Al hacer clic en el botón en HelloWorld, la app redirigirá aquí
  rutaPrincipal: "/catalogos",
  // menuArbol alberga los links que se generan en el menú
  menuArbol: [
    {
      ruta: "/catalogos",
      label: "Catálogos de la app",
      permiso: "",
      icono: "appsOutline",
    },
  ],
  // Variables globales para el módulo, configurables desde /config/empresa.
  // Si existe esta variable en el usuario, se sobrepondrá a la variable global de empresa.
};
let menuArbol = Api.fetchLocal("menuArbol") || {};
menuArbol[manifiesto.nombre] = manifiesto;
Api.saveLocal("menuArbol", menuArbol);
export default routes;

/*

Instrucciones:

- Convertir Vue() a defineComponent()
- Cambiar div.ion-page a IonPage
- Importar todos los componentes
- Cambiar todos los íconos a IonIcon, importarlos desde 'ionicons/icons' y exportarlos en data()
- Importar IonMenuButton y cambiarlo a slot="start"
- Cambiar los modelos de Vue() a standalone {...datos,...metodos}
- Filtros depreciados!
  - import {format} from 'date-fns'
  - DD cambia a dd
  - yyyy cambia a yyyy
- Ya que Wanshitong guarda fechas actualas, guardar TODAS las fechas secundarias en el formato nativo de su input
- Todos los input, checkbox y cualquier cosa que use $event DEBE ser importado
- IonContent debe ser importado o no habrá scroll!
- Todos los eventos tendrán que pasarse a los tags de los componentes
- setup() NO puede ser asíncrono!

*/
