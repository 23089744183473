import {
  createRouter,
  //createWebHistory
  createWebHashHistory,
} from "@ionic/vue-router";

let routes = [
  {
    path: "",
    component: () => import("@/common/Router.vue"),
  },
  {
    path: "/dashboard",
    component: () => import("@/common/Dashboard/Dashboard"),
  },
  {
    path: "/login",
    component: () => import("@/common/Login/Login"),
  },
  {
    path: "/login/:empresastr",
    component: () => import("@/common/Login/Login"),
    props: true,
  },
  {
    path: "/e/:empresastr",
    component: () => import("@/common/Login/Login"),
    props: true,
  },
  {
    path: "/*",
    component: () => import("@/common/404"),
  },
];

// A importarlos manualmente, webas
import ConfigRouter from "@/common/Config";
import CrmRouter from "@/apps/CRM";
import CapturaRouter from "@/apps/Captura";
import CatalogosRouter from "@/apps/Catalogos";
routes = routes
  .concat(ConfigRouter.router)
  .concat(CapturaRouter.router)
  .concat(CatalogosRouter.router)
  .concat(CrmRouter.router);
//*/
const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});
export default router;
